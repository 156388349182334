.card.percentage-widget {
    line-height: 1;

    .card-header {
        width: 100%;
        padding-bottom: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .card-body {
        padding: 0 0 1rem 0;
        width: 100%;

        .total {
            font-size: 30px;
            padding: 0 1.75rem;
            align-items: center;
            justify-content: center;
            text-align: center;
        }

        .footer {
            padding-top: 1.25rem;

            .line-titles {
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
                padding-bottom: 5px;

                span {
                    font-size: 11px;
                    font-weight: 600;
                    padding: 0 1.25rem;
                }
            }

            .line {
                height: 3px;
                width: 100%;
                position: relative;

                .line-left,
                .line-right {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    width: calc(50% + 1px);
                    transition: width 0.3s;
                }

                .line-left {
                    left: 0;
                }

                .line-right {
                    right: 0;
                }
            }

            .line-text {
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
                padding-top: 6px;
                padding: 6px 1.25rem 0 1.25rem;

                & > div {
                    &:last-child {
                        .value {
                            text-align: right;
                        }

                        .percentage {
                            text-align: right;
                        }
                    }

                    .value {
                        display: block;
                        padding-bottom: 5px;
                        font-size: 15px;
                    }

                    .percentage {
                        display: block;
                        font-size: 12px;
                    }
                }
            }
        }
    }
}
