.react-table-container {
    overflow: hidden !important;
    border-radius: 0 0 20px 20px;
    color: var(--text-colour--regular);
    width: 100%;

    .react-table-scroll-container {
        overflow-x: auto;
        .react-table {
            width: 100%;
        }
    }

    tr.clickable-row {
        cursor: pointer;

        &:hover {
            td {
                background-color: #2a78bd;
                color: #fff;

                .fa {
                    color: #fff !important;
                }

                &:first-child span {
                    width: auto;

                    &:after {
                        width: calc(100% + 6px);
                        opacity: 1;
                        left: -3px;
                    }
                }
            }
        }

        td {
            background-color: --colour-e-1;
            color: #2a78bd;
            &:first-child span {
                transition: all 0.2s;
                display: inline-flex;
                flex-flow: column nowrap;

                &:after {
                    content: '';
                    position: relative;
                    display: block;
                    background: #fff;
                    height: 1px;
                    opacity: 0;
                    transition: width 0.2s, opacity 0.2s, left 0.4s;
                    bottom: 0%;
                    width: 90%;
                    left: 0;
                }
            }
        }
    }

    .react-table tr {
        th,
        td {
            height: 52px;
            vertical-align: middle;
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
            padding: 0 1.25rem;
        }

        th {
            padding: 0 1.25rem;
        }
        th.align-left,
        td.align-left {
            text-align: left;
        }
        th.align-right,
        td.align-right {
            text-align: right;
        }
        th.align-center,
        td.align-center {
            text-align: center;
        }
        td.wrap-text {
            white-space: normal;
            word-break: break-all;
        }
        td.color-cell {
            background-color: var(--primary-translucent);
        }
        td.color-cell:hover {
            background-color: var(--primary);
            color: var(--text-colour--active);
        }
        td.cell-clickable {
            cursor: pointer;
            height: 52px;
            align-items: center;
            background-color: --colour-e-1;
            color: #2a78bd;
            cursor: pointer;

            &:hover {
                background-color: #2a78bd;
                color: #fff;
            }
            
            * {
                user-select: none;
            }
        }
    }

    .table-pagination {
        padding: 1rem 2.5rem;
        margin-top: 0;
        border-top: 1px solid var(--colour-divider);
        display: flex;
        align-items: center;
        justify-content: space-between;

        .page-size-container {
            display: flex;
            align-items: center;

            button {
                margin-left: 0.25rem;
            }
        }

        .pagination-buttons {
            .btn {
                padding: 0.2rem;
            }
        }
    }

    .divider {
        width: 100%;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }
    .search {
        width: 100%;
        flex: 1 1 auto;
        display: flex;
        align-items: center;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .download {
        flex: 0 0 auto;
        display: flex;
        align-items: center;
    }
    .fa-sort:before {
        color: rgb(204, 204, 204);
        margin-left: 5px;
    }
    .fa-sort-asc:before {
        margin-left: 5px;
    }
    .fa-sort-desc:before {
        margin-left: 5px;
    }
    .display-inline {
        display: inline;
    }
    .react-table-no-data {
        text-align: center;
    }
}
