body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-y: scroll;
    // padding-right: 0 !important;
}

// prettier-ignore
h1.h1, h2.h2, h3.h3, h4.h4, h5.h5 {
    margin-bottom: 1.25rem;
    line-height: 1;
}

h1.h1 {
    font-weight: 200;
}

h2.h2 {
    font-weight: 300;
}

h3.h3 {
    font-weight: 400;
}

h4.h4 {
    font-weight: 500;
}

h5.h5 {
    font-weight: 600;
}

hr.hr {
    margin: 1.25rem 0;
}

// prettier-ignore
body button:focus, body .btn:focus, body .btn-primary:focus, body .btn-secondary:focus {
    box-shadow: none;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a,
.fake-a {
    color: #20a8d8;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;

    &:hover {
        color: #167495;
        text-decoration: underline;
    }
}

.row {
    margin-right: -1.25rem;
    margin-left: -1.25rem;
}

// prettier-ignore
.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
	padding-right: 1.25rem;
    padding-left: 1.25rem;
}
