.menu-container {
    position: relative;

    .menu {
        display: block;
        position: absolute;
        right: 0;
        min-width: 112px;
        background-color: #ffffff;
        overflow: hidden;
        box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12),
            0 5px 5px -3px rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        max-height: 30vh;
        z-index: 3;

        &.menu-sub {
            opacity: 0;
            pointer-events: none;
            transition: opacity 0.2s;
            z-index: 3;

            &.hover {
                display: block;
                opacity: 1;
                pointer-events: all;
            }
        }
    }
}
