.context, .codes-context {

  .dates-high-level {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows:repeat(1, auto);
    grid-gap: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px;

    .select-container div[class*=control], .DateRangePicker .DateRangePickerInput {
      height: 35px;
      min-height: 0;
    }

    .DateRangePicker .DateRangePickerInput input {
      height: 33px;
      font-size: 14px;
    }

    .date-select__indicators {
      height: 33px;
    }

    .date-select__single-value {
      font-size: 14px;
    }

    .select-container > span {
      top: -19px;
      left: 0px;
    }
  }
}