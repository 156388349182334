.toast-error {
  border-radius: 5px;
  background-image: linear-gradient(
    to right,
    #f86c6b 0px,
    #f86c6b 10px,
    #333 10px,
    #333 100%
  );
}

.toast-error-body {
  margin-left: 6px;
}
