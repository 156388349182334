.spinner-card {
    height: 200px;
}

.spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column nowrap;

    &.large {
        width: 100%;
        height: 100%;
        min-height: 200px;
    }

    span {
        margin-top: 0.5rem;
    }

    svg {
        width: 100px;

        #svg_1 {
            fill: var(--text-colour--disabled);
            animation-name: fadeInFast;
            animation-duration: 1.9s;
            animation-iteration-count: infinite;
        }

        #svg_2 {
            fill: var(--text-colour--disabled);
            animation-name: fadeInMid;
            animation-duration: 1.9s;
            animation-iteration-count: infinite;
        }

        #svg_3 {
            fill: var(--text-colour--disabled);
            animation-name: fadeInSlow;
            animation-duration: 1.9s;
            animation-iteration-count: infinite;
        }
    }
}

@keyframes fadeInFast {
    // Start
    0% {
        opacity: 0;
        transform: translateX(-20px);
    }
    // Start fade in
    10% {
        opacity: 0;
        transform: translateX(-20px);
    }
    // Opacity start
    15% {
        opacity: 0;
    }
    // End fade in
    50% {
        opacity: 1;
        transform: translateX(0);
    }
    // Start fade out
    56% {
        opacity: 1;
        transform: translateX(0);
    }
    // Opacity end
    70% {
        opacity: 0;
    }
    // End fade out
    76% {
        opacity: 0;
        transform: translateX(20px);
    }
    // Hold it out
    100% {
        opacity: 0;
        transform: translateX(20px);
    }
}

@keyframes fadeInMid {
    // Start
    0% {
        opacity: 0;
        transform: translateX(-20px);
    }
    // Start fade in
    14% {
        opacity: 0;
        transform: translateX(-20px);
    }
    // Opacity start
    19% {
        opacity: 0;
    }
    // End fade in
    54% {
        opacity: 1;
        transform: translateX(0);
    }
    // Start fade out
    60% {
        opacity: 1;
        transform: translateX(0);
    }
    // Opacity end
    74% {
        opacity: 0;
    }
    // End fade out
    80% {
        opacity: 0;
        transform: translateX(20px);
    }
    // Hold it out
    100% {
        opacity: 0;
        transform: translateX(20px);
    }
}

@keyframes fadeInSlow {
    // Start
    0% {
        opacity: 0;
        transform: translateX(-20px);
    }
    // Start fade in
    18% {
        opacity: 0;
        transform: translateX(-20px);
    }
    // Opacity start
    23% {
        opacity: 0;
    }
    // End fade in
    58% {
        opacity: 1;
        transform: translateX(0);
    }
    // Start fade out
    64% {
        opacity: 1;
        transform: translateX(0);
    }
    // Opacity end
    78% {
        opacity: 0;
    }
    // End fade out
    84% {
        opacity: 0;
        transform: translateX(20px);
    }
    // Hold it out
    100% {
        opacity: 0;
        transform: translateX(20px);
    }
}
