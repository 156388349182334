.select-container {
    width: 100%;

    @media only screen and (min-width: 576px) {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .error div[class*=control] {
        border-color: var(--danger);
    }
}
