.menu-background {
    display: none;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border: none;
    background: none;

    &.active {
        display: block;
    }
}
