.u-display-none {
    display: none;
}

.blurred {
    // filter: blur(3px);
}
.dimmed {
    opacity: 0.6;
}
.u-flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.u-flex-justify-center {
    justify-content: center;
}

.u-flex-justify-space {
    justify-content: space-between;
}

.u-flex-justify-left {
    justify-content: left;
}

$blue: rgb(209, 233, 241);
$yellow: lightyellow;
$green: rgb(191, 241, 191);
$pulse: 1.1;

@keyframes pulse-blue {
    0% {
        transform: scale(1);
    }
    30% {
        transform: scale($pulse);
        background: $blue;
    }
    100% {
        transform: scale(1);
    }
}
@keyframes pulse-green {
    0% {
        transform: scale(1);
    }
    30% {
        transform: scale($pulse);
        background: $green;
    }
    100% {
        transform: scale(1);
    }
}
@keyframes pulse-yellow {
    0% {
        transform: scale(1);
    }
    30% {
        transform: scale($pulse);
        background: $yellow;
    }
    100% {
        transform: scale(1);
    }
}

.login-modal {
    .spinner-container {
        position: absolute;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.6);
        // pointer-events: none;
        display: none;
        animation-duration: 0.3s;
        // opacity: 0;
        // transition: opacity 0.2s ease;
    }
    &.loading {
        .card {
            border: none;
        }
        .spinner-container {
            display: flex;
            // opacity: 0.4;
        }
    }
}

// Custom extra styling for Bootstrap 4 cols - add to it as we go
@media (min-width: 1800px) {
    .col-xxl-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }

    .col-xxl-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    .col-xxl-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-xxl-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .col-xxl-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }

    .col-xxl-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-xxl-7 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }

    .col-xxl-8 {
        flex: 0 0 66.66666%;
        max-width: 66.66666%;
    }

    .col-xxl-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-xxl-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }

    .col-xxl-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

.btn.disabled {
    cursor: not-allowed;
}
