// General styles
#root .codes-context {
    .card-body {
        position: relative;
        padding: 1rem 2rem;
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows:repeat(9, auto);
        grid-gap: 1rem;

        .title {
            height: 28px;
            display: flex;
            align-items: center;

            p {
                font-size: 13px;
                margin: 0;
                padding: 0;
                font-weight: 500;
            }
        }

        @media only screen and (min-width: 992px) {
            display: grid;
            grid-template-columns: repeat(12, 1fr);
            grid-template-rows:repeat(6, auto);
            grid-gap: 1rem;
        }

        #date-title {
            grid-row: 1;
            grid-column: 1 / -1;
        }

        .tag-container {
            grid-row: 2;
            grid-column: 1 / -1;

            @media only screen and (min-width: 992px) {
                grid-row: 2;
                grid-column: 7 / -1;
            }
        }

        .date-container {
            grid-row: 3;
            grid-column: 1 / -1;

            @media only screen and (min-width: 992px) {
                grid-row: 2;
                grid-column: 1 / 7;
            }
        }

        #filter-title {
            grid-row: 4;
            grid-column: 1 / -1;

            @media only screen and (min-width: 992px) {
                grid-row: 3;
                grid-column: 1 / -1;
            }

        }

        .channels-container {
            grid-row: 5;
            grid-column: 1 / -1;

            @media only screen and (min-width: 992px) {
                grid-row: 4;
                grid-column: 1 / 5;
            }
        }

        .sources-container {
            grid-row: 6;
            grid-column: 1 / -1;

            @media only screen and (min-width: 992px) {
                grid-row: 4;
                grid-column: 5 / 9;
            }
        }

        .publisher-container {
            grid-row: 7;
            grid-column: 1 / -1;

            @media only screen and (min-width: 992px) {
                grid-row: 4;
                grid-column: 9 / -1;
            }
        }

        .codes-container {
            grid-row: 8;
            grid-column: 1 / -1;

            @media only screen and (min-width: 992px) {
                grid-column: 1 / -1;
                grid-row: 5;
            }

            .codes-select__control {
                height: auto;
                min-height: 56px;

                .codes-select__value-container {
                    flex-flow: row wrap;
                    padding: 0.5rem;

                    > div {
                        width: auto !important;
                    }
                }
            }
        }

        .submit-container {
            grid-row: 9;
            grid-column: 1 / -1;

            @media only screen and (min-width: 992px) {
                grid-row: 6;
                grid-column: 5 / 9;
            }

            display: flex;
            justify-content: center;

            .btn {
                width: 100%;
                max-width: 300px;
            }
        }
    }
}

// Fix bug where opening the nav at sm breaks stuff
.sidebar-show .context .card-body {
    @media only screen and (min-width: 576px) and (max-width: 767px) {
        .tag-select-container {
            max-width: 100%;
            flex: 1 1 auto;
        }

        .callout-spacer {
            width: 100%;
            height: 56px;
            max-width: 100%;
            flex: 1 1 auto;
        }

        .callout-container {
            height: auto;
            top: 69px;
            left: initial;
            max-width: 100%;
        }

        .date-select-container {
            flex: 1 1 auto;
            max-width: 100%;
        }
    }
}
