@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&family=Open+Sans:wght@400;500&display=swap');
@font-face {
    font-family: 'Material Icons Outlined';
    font-style: normal;
    font-weight: 400;
    src: url('../assets/MaterialIconsOutlined-Regular.otf');
}

:root {
    // Colours
    // =========================================================================
    // Main Colours
    --primary: rgba(42, 120, 189, 1);
    --primary-hover: #246bab;
    --primary-translucent: rgba(42, 120, 189, 0.26);
    --primary-translucent-light: rgba(42, 120, 189, 0.18);
    --primary-translucent-dark: rgba(42, 120, 189, 0.5);
    --primary-faded: #69a0d1;
    --primary-dark: #164d82;
    --primary-light: #4aaae0;
    --secondary: #2abdb8;
    --background: #e5e5e5;

    // Other colours
    --blue: var(--primary);
    --blue-light: var(--primary-light);
    --blue-dark: var(--primary-dark);
    --green-light: #81c784;
    --green: #43a047;
    --green-dark: #1b5e20;
    --brown: #6d4c41;
    --brown-light: #a1887f;
    --brown-dark: #3e2723;
    --purple: #5e35b1;
    --purple-light: #9575cd;
    --purple-dark: #311b92;
    --teal: #00897b;
    --teal-light: #4db6ac;
    --teal-dark: #004d40;
    --amber: #ffb300;
    --amber-light: #ffd54f;
    --amber-dark: #ff6f00;
    --grey: #757575;
    --grey-light: #e0e0e0;
    --grey-dark: #212121;
    --red: #e53935;
    --red-light: #e57373;
    --red-dark: #b71c1c;
    --lime: #c0ca33;
    --lime-light: #dce775;
    --lime-dark: #827717;

    // Surface elevation
    --colour-e-0: #ffffff;
    --colour-e-1: #ffffff;
    --colour-e-2: #ffffff;
    --colour-e-3: #ffffff;
    --colour-e-4: #ffffff;
    --colour-e-6: #ffffff;
    --colour-e-8: #ffffff;
    --colour-e-12: #ffffff;
    --colour-e-16: #ffffff;
    --colour-e-24: #ffffff;

    // Components
    --colour-divider: rgba(0, 0, 0, 0.12);

    // Status
    --danger: #f44336;
    --danger-hover: #e57373;
    --success: #4caf50;
    --success-hover: #81c784;
    --warning: #ffc107;
    --warning-hover: #ffd54f;
    --info: #009688;
    --info-hover: #4db6ac;
    --active-background: #ffffff;
    --colour-background-hover: var(--primary-translucent);
    --colour-background-inverse-hover: rgba(0, 0, 0, 0.1);

    // Components
    --button-focus: rgba(0, 0, 0, 0.12);
    --colour-card-media-background: #f3f3f3;

    // Ripple
    --ripple-colour: rgba(0, 0, 0, 0.7);

    // Header
    --header-background: rgba(42, 120, 189, 1);

    // Map
    --colour-map--1: #bfcfdd;
    --colour-map--2: #9ab9d5;
    --colour-map--3: #75a4cd;
    --colour-map--4: #508ec5;
    --colour-map--5: #3c82c0;
    --colour-map--6: var(--primary);
    --colour-map--default: #ccc;

    // Shadows
    // =========================================================================
    --shadow-e-0: none;
    --shadow-e-1: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12),
        0 1px 3px 0 rgba(0, 0, 0, 0.2);
    --shadow-e-2: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
        0 1px 5px 0 rgba(0, 0, 0, 0.2);
    --shadow-e-3: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.12),
        0 1px 8px 0 rgba(0, 0, 0, 0.2);
    --shadow-e-4: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
        0 2px 4px -1px rgba(0, 0, 0, 0.2);
    --shadow-e-6: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12),
        0 3px 5px -1px rgba(0, 0, 0, 0.2);
    --shadow-e-8: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12),
        0 5px 5px -3px rgba(0, 0, 0, 0.2);
    --shadow-e-9: 0 9px 12px 1px rgba(0, 0, 0, 0.14), 0 3px 16px 2px rgba(0, 0, 0, 0.12),
        0 5px 6px -3px rgba(0, 0, 0, 0.2);
    --shadow-e-12: 0 12px 17px 2px rgba(0, 0, 0, 0.14), 0 5px 22px 4px rgba(0, 0, 0, 0.12),
        0 7px 8px -4px rgba(0, 0, 0, 0.2);
    --shadow-e-16: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12),
        0 8px 10px -5px rgba(0, 0, 0, 0.2);
    --shadow-e-24: 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12),
        0 11px 15px -7px rgba(0, 0, 0, 0.2);

    --button-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
        0px 1px 5px 0px rgba(0, 0, 0, 0.12);

    // Font
    // =========================================================================
    --font-size-regular: 1rem;
    --text-colour--solid: rgba(0, 0, 0, 1);
    --text-colour--regular: rgba(0, 0, 0, 0.87);
    --text-colour--faded: #666666;
    --text-colour--extra-faded: #ddd;
    --text-colour--disabled: rgba(0, 0, 0, 0.38);
    --text-colour--hover: rgba(0, 0, 0, 1);
    --text-colour--active: rgba(255, 255, 255, 1);
    --text-inverse: rgba(255, 255, 255, 0.87);

    // Spacing
    // =========================================================================
    --padding-regular: 1.25rem;

    // Images
    // =========================================================================
    --login-background: url('../assets/img/login-background-light.svg');
}

[data-theme='dark'] {
    // Colours
    // =========================================================================
    // Main Colours
    --primary: rgba(42, 120, 189, 1);
    --primary-hover: #246bab;
    --primary-translucent: rgba(42, 120, 189, 0.26);
    --primary-translucent-light: rgba(42, 120, 189, 0.18);
    --primary-translucent-dark: rgba(42, 120, 189, 0.5);
    --primary-dark: #164d82;
    --primary-light: #4aaae0;
    --secondary: #2abdb8;
    --background: #121212;

    // Other colours
    --blue: var(--primary);
    --blue-light: var(--primary-light);
    --blue-dark: var(--primary-dark);
    --green: #66bb6a;
    --green-light: #c0ebb7;
    --green-dark: #388e3c;
    --brown: #8d6e63;
    --brown-light: #d7ccc8;
    --brown-dark: #5d4037;
    --purple: #7e57c2;
    --purple-light: #d1c4e9;
    --purple-dark: #512da8;
    --teal: #26a69a;
    --teal-light: #b2dfdb;
    --teal-dark: #00796b;
    --amber: #ffee58;
    --amber-light: #fff9c4;
    --amber-dark: #fbc02d;
    --grey: #bdbdbd;
    --grey-light: #f5f5f5;
    --grey-dark: #616161;
    --red: #ef5350;
    --red-light: #ffcdd2;
    --red-dark: #d32f2f;
    --lime: #d4e157;
    --lime-light: #f0f4c3;
    --lime-dark: #afb42b;

    --blue-hover: var(--primary);
    --blue-light-hover: var(--primary-light);
    --blue-dark-hover: var(--primary-dark);
    --green-hover: #66bb6a;
    --green-light-hover: #c8e6c9;
    --green-dark-hover: #388e3c;
    --brown-hover: #8d6e63;
    --brown-light-hover: #d7ccc8;
    --brown-dark-hover: #5d4037;
    --purple-hover: #7e57c2;
    --purple-light-hover: #d1c4e9;
    --purple-dark-hover: #512da8;
    --teal-hover: #26a69a;
    --teal-light-hover: #b2dfdb;
    --teal-dark-hover: #00796b;
    --amber-hover: #ffee58;
    --amber-light-hover: #fff9c4;
    --amber-dark-hover: #fbc02d;
    --grey-hover: #eeeeee;
    --grey-light-hover: #fafafa;
    --grey-dark-hover: #757575;
    --red-hover: #ef5350;
    --red-light-hover: #ffcdd2;
    --red-dark-hover: #e53935;
    --lime-hover: #d4e157;
    --lime-light-hover: #e6ee9c;
    --lime-dark-hover: #c0ca33;

    // Surface elevation
    --colour-e-0: #1e1e1e;
    --colour-e-1: #232323;
    --colour-e-2: #232323;
    --colour-e-3: #252525;
    --colour-e-4: #272727;
    --colour-e-6: #2c2c2c;
    --colour-e-8: #2e2e2e;
    --colour-e-12: #333333;
    --colour-e-16: #353535;
    --colour-e-24: #383838;

    // Components
    --colour-divider: #2e2e2e;

    // Text
    --text-colour--solid: rgba(255, 255, 255, 1);
    --text-colour--regular: #e3e3e3;
    --text-colour--hover: rgba(255, 255, 255, 1);
    --text-colour--faded: #a7a7a7;
    --text-colour--extra-faded: #ddd;
    --text-colour--disabled: rgba(255, 255, 255, 0.38);

    --text-inverse: rgba(0, 0, 0, 0.87);

    // Status
    --danger: #cf6679;
    --danger-hover: #b85467;
    --success: #66cfbc;
    --success-hover: #49a695;
    --warning: #cfbc66;
    --warning-hover: #ab9949;
    --info: #38c0ff;
    --info-hover: #2396cc;
    --active-background: #ffffff;
    --colour-background-hover: var(--colour-e-24);
    --colour-background-inverse-hover: rgba(255, 255, 255, 0.1);
    --colour-card-media-background: var(--colour-e-0);

    // Components
    --button-focus: rgba(255, 255, 255, 0.12);

    // Ripple
    --ripple-colour: rgba(255, 255, 255, 0.7);

    // Header
    --header-background: #272727;

    // Map
    --colour-map--1: #243442;
    --colour-map--2: #1c3b57;
    --colour-map--3: #264d70;
    --colour-map--4: #27567f;
    --colour-map--5: #508ec5;
    --colour-map--6: var(--primary);
    --colour-map--default: #2e2e2e;

    // Shadows
    // =========================================================================
    --shadow-e-0: none;
    --shadow-e-1: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12),
        0 1px 3px 0 rgba(0, 0, 0, 0.2);
    --shadow-e-2: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
        0 1px 5px 0 rgba(0, 0, 0, 0.2);
    --shadow-e-3: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.12),
        0 1px 8px 0 rgba(0, 0, 0, 0.2);
    --shadow-e-4: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
        0 2px 4px -1px rgba(0, 0, 0, 0.2);
    --shadow-e-6: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12),
        0 3px 5px -1px rgba(0, 0, 0, 0.2);
    --shadow-e-8: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12),
        0 5px 5px -3px rgba(0, 0, 0, 0.2);
    --shadow-e-9: 0 9px 12px 1px rgba(0, 0, 0, 0.14), 0 3px 16px 2px rgba(0, 0, 0, 0.12),
        0 5px 6px -3px rgba(0, 0, 0, 0.2);
    --shadow-e-12: 0 12px 17px 2px rgba(0, 0, 0, 0.14), 0 5px 22px 4px rgba(0, 0, 0, 0.12),
        0 7px 8px -4px rgba(0, 0, 0, 0.2);
    --shadow-e-16: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12),
        0 8px 10px -5px rgba(0, 0, 0, 0.2);
    --shadow-e-24: 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12),
        0 11px 15px -7px rgba(0, 0, 0, 0.2);

    --button-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
        0px 1px 5px 0px rgba(0, 0, 0, 0.12);

    // Font
    // =========================================================================
    --font-size-regular: 1rem;
    --text-colour--active: rgba(255, 255, 255, 1);
    --text-regular: rgba(255, 255, 255, 0.87);

    // Spacing
    // =========================================================================
    --padding-regular: 1.25rem;

    // Images
    // =========================================================================
    --login-background: url('../assets/img/login-background-dark.svg');
}

// General
// =============================================================================
html body {
    color: var(--text-colour--regular);
    background-color: var(--background);
    font-family: 'Open Sans', 'Noto Sans', sans-serif;
}

// Icons
// =============================================================================
.material-icons-outlined {
    font-family: 'Material Icons Outlined';
    font-weight: normal;
    font-style: normal;
    font-size: 24px; /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
}

// Bugfix
// =============================================================================
body > iframe {
    display: none;
}

// Text
// =============================================================================
.text-success {
    color: var(--success) !important;
}

.text-warning {
    color: var(--warning) !important;
}

.text-danger {
    color: var(--danger) !important;
}

// Checkbox
// =============================================================================
.checkbox-personalised {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;

    .custom-checkbox-container {
        cursor: pointer;
    }

    label {
        cursor: pointer;
        margin: 0;
        padding-left: 0.75rem;
        height: 100%;
    }
}

// Buttons
// =============================================================================
button {
    &:focus {
        outline: none;
    }
}

.btn {
    padding: 0.375rem 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.875rem;
    padding: 0 24px;
    height: 36px;
    box-shadow: var(--button-shadow);
    border-radius: 99999px;
    min-width: 70px;
    text-transform: uppercase;
    font-weight: 500;
    cursor: pointer;

    &.disabled {
        cursor: default;
    }

    &:focus {
        box-shadow: var(--button-shadow) !important;
        outline: none;
    }

    span {
        font-size: 0.875rem;
        line-height: 1;

        &.icon {
            font-size: 21px;
            text-transform: none;
            font-weight: 400;
        }
    }
}

.btn.btn-sm {
    padding: 0.25rem 0.5rem;
    font-size: 0.76562rem;
    line-height: 1.5;
}

.btn.btn-outline-secondary {
    color: var(--text-colour--regular);
    box-shadow: none;

    &:active,
    &:focus,
    &:hover,
    &.active {
        box-shadow: none !important;
    }
}

.btn-primary {
    background-color: var(--primary);
}

.btn-outline-primary {
    color: var(--text-colour--regular);
}

.btn-primary,
.btn-outline-primary {
    border-color: var(--primary);

    &:hover,
    &:active {
        background-color: var(--primary-hover) !important;
        border-color: var(--primary-hover) !important;
        color: var(--text-colour--active) !important;
    }
}

.btn-primary {
    background: var(--primary);

    &:disabled {
        background: var(--primary-faded) !important;
        border-color: var(--primary-faded) !important;
    }
}

.btn-danger {
    background-color: var(--danger);
    border-color: var(--danger);

    &:hover {
        background-color: var(--danger-hover);
        border-color: var(--danger-hover);
    }
}

button.ink-ripple {
    border: none;
    background: none;
    color: var(--primary);
    border-radius: 99999px;
    height: 36px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: none;
    cursor: pointer;
    padding: 0 1rem;

    &[disabled] {
        background: none;
        color: var(--text-colour--disabled);
        cursor: default;
    }

    &:not(:disabled):not(.disabled) {
        &:hover {
            background-color: var(--primary-translucent-light);
            color: var(--primary);
        }

        &:focus,
        &:active {
            outline: 0;
            background-color: var(--primary-translucent);
            box-shadow: none !important;
            color: var(--primary);
        }
    }
}

.btn-link {
    padding: 0;
    margin: 0;
    border: 0;
    line-height: 1;
    border-radius: none;
    min-height: 0;
    box-shadow: none !important;
    background: none;
    transition: background-color 0.3s;
    text-transform: none;
    display: flex;
    align-items: center;
    position: relative;
    font-size: 0.875rem;
    padding: 0 0 0.08rem 0;
    text-decoration: none;
    color: #167495;
    height: auto;

    &:before {
        content: '';
        border-bottom: 1px solid #20a8d8;
        border-radius: 0;
        position: absolute;
        top: 0;
        bottom: -3px;
        left: -3px;
        right: -3px;
        opacity: 0;
        pointer-events: 0;
        transition: color 0.2s, border-color 0.2s, opacity 0.2s;
    }

    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
        box-shadow: none !important;

        &:before {
            opacity: 1;
            border-color: #167495;
        }
    }

    .icon {
        font-size: 0.875rem;
    }
}

.btn-text {
    margin: 0;
    border: 0;
    line-height: 1;
    min-height: 0;
    box-shadow: none !important;
    background: none;
    transition: background-color 0.3s;
    text-transform: none;
    display: flex;
    align-items: center;
    position: relative;
    font-size: 0.875rem;
    text-decoration: none;
    color: #167495;

    &:hover {
        text-decoration: none;
        box-shadow: none !important;
        background-color: var(--primary-translucent);
        color: var(--primary) !important;
    }

    &:focus,
    &:active {
        text-decoration: none;
        box-shadow: none !important;
        background-color: var(--primary-translucent-dark) !important;
        color: var(--primary) !important;
    }

    .icon {
        font-size: 0.875rem;
    }
}

.btn-lg {
    padding: 0.5rem 1rem;
    font-size: 1.09375rem;
    line-height: 1.5;
}

a {
    &:hover {
        text-decoration: none !important;
    }
}

.btn-twitter {
    background-color: #1d9bf0;
    border-color: #1d9bf0;

    &:hover {
        background-color: #1583aa;
        border-color: #1583aa;
    }
}

.btn-linkedin {
    background-color: #0a66c2;
    border-color: #0a66c2;

    &:hover {
        background-color: #004182;
        border-color: #004182;
    }
}

.btn-email {
    background-color: var(--text-colour--faded);
    border-color: var(--text-colour--faded);

    &:hover {
        background-color: var(--text-colour--solid);
        border-color: var(--text-colour--solid);
    }
}

// Header
// =============================================================================
#header {
    background-color: var(--header-background);
    color: var(--text-colour--active);
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 53px;
    position: fixed;
    width: 100%;
    z-index: 1020;

    // Logo
    .navbar-brand {
        padding: 1.25rem;

        img {
            filter: grayscale(100%) brightness(100);
            -webkit-filter: grayscale(100%) brightness(100);
        }
    }

    .navbar-nav {
        height: 100%;

        .nav-item {
            color: var(--text-colour--active);
            height: 100%;

            a {
                height: 100%;
                display: flex;
                align-items: center;
                padding: 0 1.25rem;
                transition: background-color 0.2s, color 0.2s;

                &:hover {
                    color: var(--text-colour--active);
                    background-color: var(--colour-background-inverse-hover);
                }

                &[aria-expanded='true'] {
                    color: var(--text-colour--active);
                    background-color: var(--colour-background-inverse-hover);
                }

                span {
                    color: var(--text-colour--active);
                }

                .img-avatar {
                    border-radius: 18%;
                    margin: 0 0 0 0.75rem;
                }
            }

            &.show {
                .dropdown-menu {
                    opacity: 1;
                    max-height: 999px;
                    transform: scale(1, 1);
                }
            }

            .dropdown-menu {
                background-color: var(--colour-e-8);
                box-shadow: var(--shadow-e-8);
                border: none;
                border-radius: 4px;
                padding: 8px 0;
                min-width: 256px;
                top: 0;
                opacity: 0;
                max-height: 1px;
                transition: opacity 0.2s, max-height 10s;
                overflow: hidden;

                .dropdown-header {
                    display: none;
                }

                .dropdown-item {
                    color: var(--text-colour--regular);
                    border: none;
                    padding: 12px 16px;
                    transition: background-color 0.3s;
                    background-color: var(--colour-e-8);
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    &:hover {
                        background-color: var(--colour-background-hover);
                        color: var(--text-colour--hover);
                    }

                    .icon {
                        margin: 0 12px 0 0;
                    }
                }
            }
        }
    }
}

.app-body {
    @media only screen and (min-width: 992px) {
        padding-top: 53px;
    }
}

// Left Menu
// =============================================================================
html .app .sidebar {
    background: none;
    width: 100px;
    height: calc(100vh - 55px);
    background-color: var(--colour-e-0);
    box-shadow: none;
    border: solid;
    border-width: 0 1px 0 0;
    border-color: var(--colour-divider);
    margin-left: 0;
    position: fixed;
    left: 0;
    top: 53px;
    bottom: 0;

    .sidebar-minimizer {
        display: none;
    }

    .nav {
        width: 100%;

        li {
            margin: 0 !important;

            a {
                padding: 25px 0 0 0;
                width: 100%;
                align-items: center;
                justify-content: center !important;

                &:hover {
                    background: none;

                    span {
                        color: var(--text-colour--solid) !important;

                        i:after {
                            background: var(--colour-e-4);
                            color: var(--text-colour--solid) !important;
                        }
                    }
                }

                &.active {
                    background: none;

                    .icon {
                        color: var(--text-colour--active) !important;

                        &:after {
                            background: var(--primary);
                        }
                    }
                }

                span {
                    flex-flow: column;
                    align-items: center;
                    justify-content: center;
                    font-size: 12px;
                    padding: 0 10px;
                    text-align: center;
                    color: var(--text-colour--regular);
                    transition: color 0.2s;

                    .icon {
                        margin: 0 0 5px 0;
                        color: var(--text-colour--regular) !important;
                        position: relative;
                        z-index: 1;
                        transition: color 0.2s;
                        font-size: 1.2rem;

                        &:after {
                            content: '';
                            position: absolute;
                            top: -5px;
                            left: -6px;
                            right: -6px;
                            bottom: -5px;
                            border-radius: 9999px;
                            transition: background-color 0.2s;
                            z-index: -1;
                        }
                    }
                }
            }
        }

        .version-number-container {
            cursor: default !important;

            .version-number {
                border: none !important;
                padding: 0;
                margin: 0;
                display: flex;
                flex-flow: column nowrap;
                align-items: center;
                justify-content: center;

                .version-border {
                    border: none !important;
                }
            }
        }
    }
}

// Hide groupings
.sidebar {
    .nav-link {
        display: none !important;
    }
    .nav-dropdown-items .nav-link {
        display: flex !important;
    }
}

// Main
// =============================================================================
.main {
    margin-left: 100px !important;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    overflow: hidden;

    .container-fluid {
        max-width: 1400px;
    }
}

// Input - Reactstrap
// =============================================================================
input,
input.form-control {
    background: none;
    color: var(--text-colour--regular);
    padding: 0;
    margin: 0;
    border: 1px solid var(--text-colour--disabled);
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.15px;
    text-align: left;
    padding: 15px 12px 16px 16px;

    &:focus {
        color: var(--text-colour--solid);
        background: none;
        border-color: var(--primary);
        outline: 0;
        box-shadow: none;
    }
}

// Input - Custom
// =============================================================================
.input-container {
    display: flex;
    align-items: center;
    justify-content: stretch;
    border: none;
    padding: 0;
    position: relative;
    height: 56px;
    margin-bottom: 1rem;
    flex: 1 1 auto;

    &:before {
        content: '';
        bottom: 0;
        width: 12px;
        height: 100%;
        border: 1px solid var(--text-colour--disabled);
        border-width: 1px 0 1px 1px;
        border-radius: 4px 0 0 4px;
        pointer-events: none;
        transition: border-color 0.2s;
        z-index: 1;
    }

    &:after {
        content: '';
        width: 100%;
        height: 100%;
        flex: 1 1 auto;
        border: 1px solid var(--text-colour--disabled);
        border-width: 1px 1px 1px 0;
        border-radius: 0 4px 4px 0;
        pointer-events: none;
        transition: border-color 0.2s;
        z-index: 1;
    }

    .notch {
        position: relative;
        padding: 0 3px 0 3px;
        height: 100%;
        pointer-events: none;
        white-space: nowrap;

        &:before {
            content: '';
            position: absolute;
            left: 0;
            width: 100%;
            height: 100%;
            border: 1px solid var(--text-colour--disabled);
            border-width: 1px 0 0 0;
            opacity: 1;
            transition: border-color 0.2s, opacity 0.2s, border-width 0s;
            z-index: 1;
        }

        &:after {
            content: '';
            position: absolute;
            left: 0;
            width: 100%;
            height: 100%;
            border: 1px solid var(--text-colour--disabled);
            border-width: 0 0 1px 0;
            opacity: 1;
            transition: border-color 0.2s, opacity 0.2s;
            z-index: 1;
        }

        label {
            color: var(--text-colour--faded);
            font-size: 12px;
            transition: transform 0.2s, color 0.2s;
            line-height: 1;
            margin: 0;
            padding: 0;
            z-index: 1;
            transform-origin: center left;

            z-index: 1;
        }

        label {
            transform: translate(0px, 17px) scale(1.3);
            position: relative;
        }
    }

    input {
        position: absolute;
        width: 100%;
        height: 100%;
        background: none;
        color: var(--text-colour--regular);
        margin: 0;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.15px;
        text-align: left;
        border: none;
        flex: 1 1 auto;
        padding: 15px;
        cursor: text;

        &:focus {
            color: var(--text-colour--regular);
            background: none;
            outline: 0;
            box-shadow: none;
        }
    }

    &.focus,
    &.fill {
        .notch {
            &:before {
                opacity: 0;
            }

            label {
                transform: translate(0, -12px);
            }
        }
    }

    &.focus {
        &:before {
            border-color: var(--primary);
            border-width: 2px 0 2px 2px;
        }
        &:after {
            border-color: var(--primary);
            border-width: 2px 2px 2px 0;
        }

        .notch {
            &:before {
                border-color: var(--primary);
                border-width: 2px 0 0 0;
                transition: border-color 0.2s, opacity 0.2s;
            }

            &:after {
                border-color: var(--primary);
                border-width: 0 0 2px 0;
            }

            label {
                color: var(--primary);
            }
        }
    }
}

// Select (react-select)
// =============================================================================
//div[class*='container'] {
.select-container {
    // Textbox
    div[class*='control'] {
        background: none;
        border: 1px solid var(--text-colour--disabled);
        height: 56px;
        box-shadow: none;
        font-size: var(--font-size-regular);

        &[class*='__control--is-focused'] {
            border: 1px solid var(--primary);
        }

        & > div > div {
            color: var(--text-colour--regular);
            
            &[class*='placeholder'] {
                color: var(--text-colour--disabled);
            }
        }

        span {
            display: none;
        }
    }
    
    // Dropdown
    div[class*='menu'] {
        background-color: var(--colour-e-8);
        overflow: hidden;
        box-shadow: var(--shadow-e-8);
        border-radius: 4px;
        transition: opacity 0.2s;
        z-index: 2;

        div[class*='menu-list'],
        div[class*='MenuList'] {
            overflow-y: auto;
            overflow-x: hidden;
            padding: 8px 0;

            div[class*='option'] {
                background: var(--colour-e-8);
                padding: 12px 16px;
                cursor: pointer;
                transition: background-color 0.3s;
                font-size: 16px;
                color: var(--text-colour--regular);

                &:hover {
                    background-color: var(--colour-background-hover);
                    color: var(--text-colour--solid);
                }
            }
        }
    }

    // Disabled
    &.disabled {
        & > span {
            color: var(--text-colour--disabled);
        }

        div[class*='control'] {
            border-color: var(--text-colour--extra-faded);


            &[class*='__control--is-focused'] {
                border: 1px solid var(--primary);
            }

            & > div > div {
                color: var(--text-colour--disabled);
            }
        }
    }
}

// Label hack
.select-container {
    position: relative;

    & > span {
        position: absolute;
        left: 7px;
        top: -7px;
        line-height: 1;
        font-size: 12px;
        background-color: var(--colour-e-4);
        width: auto;
        height: auto;
        padding: 2px 4px;
        display: block;
        margin: 0 !important;
        z-index: 1;
    }
}

// Menu
// =============================================================================
.menu-container {
    .menu {
        background-color: var(--colour-e-8);
        box-shadow: var(--shadow-e-8);
    }
}

// List
// =============================================================================
.list {
    .option {
        background: var(--colour-e-8);
        border-color: var(--colour-divider);
        border: none;

        &:hover {
            background-color: var(--colour-background-hover);
            color: var(--text-colour--solid);
        }
    }
}

// Cards
// =============================================================================
.card {
    background-color: var(--colour-e-1);
    box-shadow: var(--shadow-e-1);
    border-radius: 20px;
    border: none;

    .card-header {
        background: none;
        border-color: var(--colour-e-1);
        font-size: 1.25rem;
        color: var(--text-colour--solid);
        font-weight: 500;
        letter-spacing: 0.0125em;
        display: flex;
        align-items: center;
        border-bottom: 1px solid var(--colour-divider);
        border: none;
    }

    .card-media {
        padding: 0.5rem 1.25rem 0.5rem 1.25rem;
        border: 1px solid var(--colour-divider);
        border-width: 0 0 1px 0;
        background: var(--colour-card-media-background);
        border-radius: 22px 14px 0 0;
        display: flex;
        align-items: center;
        justify-content: center;

        & > * {
            flex: 1 1 auto;
        }
    }

    .card-body {
        background: none;
        border: none;
        border-radius: 20px;

        h4 {
            margin-bottom: 1.25rem;
            font-size: 1.25rem;
            font-weight: 500;
        }
    }

    &.card-table {
        .card-body {
            padding: 0;
        }
    }

    &.has-media {
        padding-top: 0;
        .card-body {
            padding: 0;
        }
    }

    &.card--grow {
        height: calc(100% - 1.5rem);
        margin-bottom: 1.5rem;
    }
}

// Card accents
.card {
    &.card-accent-success,
    &.card-accent-danger {
        position: relative;

        &:before {
            content: '';
            top: -1px;
            left: -1px;
            right: -1px;
            bottom: -1px;
            position: absolute;
            border: 1px solid var(--success);
            border-radius: 20px;
            pointer-events: none;
        }
    }

    &.card-accent-success:before {
        border: 1px solid var(--success);
    }

    &.card-accent-danger:before {
        border: 1px solid var(--danger);
    }
}

.sidebar-minimizer {
    display: none;
}

// Datepicker
// =============================================================================
.DateRangePicker {
    .DateRangePickerInput {
        background: none;
        height: 56px;
        border: solid 1px var(--text-colour--disabled);

        &.DateRangePickerInput__withBorder {
            border-color: var(--text-colour--disabled);
        }

        .DateInput {
            background: none;
            height: 100%;

            input {
                background: none;
                color: var(--text-colour--regular);
                height: 55px;
                padding: 4px 0 0 0;
                border-width: 0 0 4px 0;
                font-size: var(--font-size-regular);

                &.DateInput_input__focused {
                    border-color: var(--primary);
                }
            }
        }

        .DateRangePicker_picker,
        .DateInput_fang {
            margin-top: 3px;
        }

        .DateInput_fang path {
            fill: var(--colour-e-16);
            stroke: var(--colour-e-16);
        }

        .DateRangePicker_picker {
            background-color: var(--colour-e-4);
            box-shadow: var(--shadow-e-8);
            border: none;
            border-radius: 4px;
            min-width: 256px;
            transition: opacity 0.2s, max-height 10s;
            overflow: hidden;
            z-index: 10;

            .DayPicker {
                background-color: var(--colour-e-8);
                position: relative;

                &:before {
                    content: '';
                    width: 100%;
                    height: 86px;
                    background-color: var(--colour-e-16);
                    top: 0;
                    left: 0;
                    position: absolute;
                }

                .DayPickerKeyboardShortcuts_show__bottomRight::before {
                    border-right: 33px solid var(--secondary);
                }

                .DayPickerKeyboardShortcuts_panel {
                    background-color: var(--colour-e-8);
                }

                .DayPickerNavigation_button {
                    background-color: var(--colour-e-8);
                    border-radius: 9999px;

                    svg {
                        fill: var(--text-colour--regular);
                    }
                }

                .CalendarMonthGrid {
                    background: none;

                    .CalendarMonthGrid_month__horizontal {
                        background: none;

                        .CalendarMonth {
                            background: none;
                            color: var(--text-colour--regular);

                            .CalendarMonth_caption {
                                color: var(--text-colour--regular);
                            }

                            .CalendarMonth_table {
                                td {
                                    color: var(--text-colour--regular);
                                    background: none;
                                    border: none;

                                    &.CalendarDay__selected {
                                        border-color: var(--primary);
                                        background-color: var(--primary);
                                        color: var(--text-colour--active);

                                        &.CalendarDay__selected_start {
                                            border-top-left-radius: 50%;
                                            border-bottom-left-radius: 50%;
                                        }

                                        &.CalendarDay__selected_end {
                                            border-top-right-radius: 50%;
                                            border-bottom-right-radius: 50%;
                                        }
                                    }

                                    &.CalendarDay__blocked_out_of_range {
                                        color: var(--text-colour--disabled);
                                        border: var(--text-colour--disabled);
                                    }

                                    &.CalendarDay__hovered_span {
                                        background-color: var(--primary-translucent);
                                    }

                                    &.CalendarDay__selected_span {
                                        border-color: var(--primary);
                                        background-color: var(--primary-translucent);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .DateRangePickerInput_arrow {
            color: var(--text-colour--regular);
        }
    }
}

// Context
// =============================================================================
.context {
    #context-main-body {
        @media (min-width: 1800px) {
            align-items: center !important;
            justify-content: center !important;
        }

        .tag-select-container {
            @media (min-width: 1800px) {
                flex: 0 0 35% !important;
                max-width: 35% !important;
            }

            & > .tag-select-container {
                flex: 1 1 auto !important;
                max-width: 100% !important;
            }
        }

        .date-select-container {
            @media (min-width: 1800px) {
                flex: 1 1 auto !important;
                max-width: 100% !important;
            }
        }

        .callout-container {
            @media (min-width: 1800px) {
                flex: 0 0 auto !important;
                max-width: none !important;
            }
        }
    }

    #advanced-options .expand-button {
        border-radius: 0 0 20px 20px !important;
        box-shadow: none !important;
    }
}

// Footer
// =============================================================================
footer {
    width: 100%;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: var(--colour-e-1);
    padding: 0 1.25rem;
    border: 1px solid var(--colour-divider);
    border-width: 1px 0 0 0;
}

// Callouts
// =============================================================================
.callout.callout-box {
    &.callout-danger {
        border-left-color: var(--danger);
        border-right-color: var(--danger);
    }
}

// Summary Widgets
// =============================================================================
.widget {
    padding: 14px 18px;

    * {
        line-height: 1;
        user-select: none;
    }

    .card-body {
        align-items: center;
        justify-content: center;

        > * {
            flex: 1 1 auto;
        }

        .material-icons-outlined {
            margin-right: 10px;
            font-size: 26px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 0 0 auto;
            width: 20%;

            &.info {
                color: var(--info);
            }

            &.success {
                color: var(--success);
            }

            &.danger {
                color: var(--danger);
            }

            &.warning {
                color: var(--warning);
            }
        }

        .h5 {
            display: block;
            margin-bottom: 5px !important;
            flex: 1 1 auto;
        }
    }
}

// Percentage Widgets
// =============================================================================
.percentage-widget {
    .card-header {
        color: var(--text-colour--faded);
    }

    .card-body {
        .total {
            color: var(--text-colour--regular);
        }

        .footer {
            .line-titles {
                span {
                    color: var(--text-colour--faded);
                }
            }

            .line {
                .line-left {
                    background-color: var(--success);
                }
                .line-right {
                    background-color: var(--danger);
                }
            }

            .line-text {
                & > div:first-child .value {
                    color: var(--success);
                }

                & > div:last-child .value {
                    color: var(--danger);
                }

                .percentage {
                    color: var(--text-colour--faded);
                }
            }
        }
    }
}

// Checkboxes
// =============================================================================
.custom-checkbox-container .hidden-dom-checkbox:checked ~ .custom-checkbox {
    background-color: var(--primary);
    border-color: var(--primary);
}

.custom-checkbox-container .hidden-dom-checkbox:checked ~ .custom-checkbox-shade {
    background-color: var(--primary-translucent);
}

// Tables
// =============================================================================
.react-bs-table-container {
    overflow: hidden !important;
    border-radius: 0 0 20px 20px;

    .react-bs-table-tool-bar {
        border-bottom: 1px solid var(--colour-divider);
        height: 52px;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0 1.25rem;

        & > .row {
            flex: 1 1 auto;
            flex-flow: row nowrap;
            margin: 0;

            .search,
            .more {
                display: flex;
                align-items: center;
            }

            .search {
                width: 100%;
                flex: 1 1 auto;

                .search-icon {
                    margin-right: 1.25rem;
                    width: 24px;
                }

                .react-bs-table-search-form {
                    flex: 1 1 auto;

                    input {
                        border: none;
                        border-radius: 0;
                    }
                }
            }

            .more {
                flex: 0 0 auto;
            }
        }
    }

    .react-bs-table-bordered {
        border: none;

        table,
        table.table-bordered {
            border: none;

            tr {
                th,
                td {
                    color: var(--text-colour--regular);
                    border-width: 0 0 1px 0;
                    border-color: var(--colour-divider);

                    &:first-child {
                        padding-left: 2.5rem;
                    }

                    &:last-child {
                        padding-right: 2.5rem;
                    }

                    & > span {
                        height: 20px;
                    }
                }

                th {
                    // height: 56px;
                    height: 52px;
                    padding: 0 1.25rem;
                    user-select: none;
                    vertical-align: middle;

                    .fa {
                        margin: 0 0 0 8px !important;
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                    }
                }

                td {
                    // height: 52px;
                    height: 48px;
                    padding: 0 16px;
                    vertical-align: middle;

                    span {
                        display: flex;
                        align-items: center;
                    }

                    &[style*='text-align: right;'] {
                        span {
                            align-items: center;
                            justify-content: flex-end;
                        }
                    }
                }

                &.clickable-row {
                    transition: background 0.2s;

                    td:first-child {
                        span {
                            display: inline-flex;
                            position: relative;
                            align-items: center;
                            transition: color 0.2s;

                            &:before {
                                content: '';
                                position: absolute;
                                display: block;
                                background: var(--primary);
                                height: 1px;
                                opacity: 0;
                                transition: width 0.2s, opacity 0.2s, left 0.4s;
                                top: 100%;
                                width: 90%;
                                left: 0;
                            }
                        }
                    }

                    &:hover {
                        background: var(--colour-e-4);

                        td:first-child span {
                            color: var(--primary) !important;

                            &:before {
                                width: calc(100% + 6px);
                                opacity: 1;
                                left: -3px;
                            }
                        }
                    }

                    td.clickable-cell {
                        background: none;

                        &:hover {
                            color: var(--text-colour--regular);
                        }
                    }
                }

                &:last-child td {
                    border-bottom: none;
                }
            }
        }
    }
}

.react-bs-container-footer {
    th {
        height: 48px !important;
        border: none;
    }
}

.referral-link-row td {
    height: auto !important;

    span {
        display: block !important;
        height: auto !important;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

// Coloured Rows
// =====================
.react-bs-table-container .react-bs-container-body table tr {
    background: none !important;

    &.dangerFaded td:first-child {
        color: var(--danger);
    }

    &.primaryFaded td:first-child {
        color: var(--primary);
    }

    &.successFaded td:first-child {
        color: var(--success);
    }

    &.warningFaded td:first-child {
        color: var(--warning);
    }

    &.infoFaded td:first-child {
        color: var(--info);
    }
}

// Modal - Code Select
// =============================================================================
.modal {
    .modal-dialog.code-select-modal {
        max-width: 500px;

        .modal-body {
            padding: 0;
            height: auto;
            max-height: none;
            overflow: hidden;

            .react-bs-table-container {
                .react-bs-table-tool-bar {
                    margin: 0;
                    padding: 0;

                    & > .row {
                        & > .search:first-child {
                            padding-left: 24px;
                        }

                        & > div:last-child {
                            padding-right: 24px;
                        }
                    }
                }

                .react-bs-table {
                    overflow-y: auto !important;
                    overflow-x: hidden !important;
                    height: calc(75vh - 250px) !important;
                    border-radius: 0;
                    border-bottom: 1px solid var(--colour-divider);
                    max-height: 400px;

                    .react-bs-container-body {
                        overflow-y: auto !important;
                        overflow-x: hidden !important;
                    }

                    table {
                        table-layout: fixed;

                        colgroup col:first-child,
                        tr td:first-child {
                            width: 66px !important;
                            min-width: 66px !important;

                            .checkbox-personalised {
                                width: 100% !important;
                                height: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                margin: 0;
                            }
                        }

                        colgroup col:last-child,
                        tr td:last-child {
                            width: calc(100% - 66px) !important;
                        }

                        tr {
                            td {
                                &:first-child {
                                    padding: 8px 8px 8px 24px;
                                    .checkbox-personalised {
                                        width: 20px;
                                        padding: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .submit-container {
                padding: 1.25rem calc(1.25rem + 15px);
            }
        }
    }
}

// Modal - Basket
// =============================================================================
.modal {

    .modal-dialog.basket {
        max-width: 500px;

        .modal-content {
            overflow: hidden;
            min-width: 0;
            
            .modal-header {
                -webkit-box-shadow: 0px 4px 3px -3px rgba(0,0,0,0.25);
                -moz-box-shadow: 0px 4px 3px -3px rgba(0,0,0,0.25);
                box-shadow: 0px 4px 3px -3px rgba(0,0,0,0.25);
                z-index: 1;
    
                .modal-title {
                    width: 100%;
                    
                    .title {
                        font-size: 17px;
                    }
    
                    .description {
                        font-size: 12px;
                        color: var(--text-colour--faded);
                        min-width: 100%;
                        width: 0;
                    }
                }
            }
            
            .modal-body {
                overflow-y: scroll;
                max-height: calc(100vh - 300px);
                justify-content: safe center;
    
                .products {
                    width: auto;
                    max-width: none;

                    .product:not(:first-child) {
                        padding-top: 12px;
                    }
    
                    .product:not(:last-child) {
                        padding-bottom: 12px;
                        border-bottom: 1px solid #eee;
                    }
    
                    .product {
                        display: flex;
                        align-items: flex-start;
    
                        .image {
                            border-radius: 2px;
                            position: relative;
    
                            &:before {
                                content: '';
                                -webkit-box-shadow: inset 0px 0px 2px 0px rgba(0, 0, 0, 0.5);
                                -moz-box-shadow: inset 0px 0px 2px 0px rgba(0, 0, 0, 0.5);
                                box-shadow: inset 0px 0px 2px 0px rgba(0, 0, 0, 0.5);
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                border-radius: 3px;
                            }
    
                            img {
                                width: 80px;
                                height: auto;
                                max-height: 80px;
                            }
                        }
    
                        .title {
                            font-size: 16px;
                            padding: 0px 15px;
                        }
    
                        .tags {
                            color: var(--text-colour--faded);
                            padding: 0px 18px;
                            font-size: 13px;
    
                            .tag-title {
                                font-weight: 600;
                                padding-right: 5px;
                            }
                        }
                    }
                }
            }
        }
    }
}

// Tabs
// =============================================================================
.app .nav-tabs {
    flex-flow: row wrap;
    justify-content: stretch;
    user-select: none;
    border-bottom: 1px solid var(--colour-divider);

    .nav-item {
        width: auto;
        flex: 1 1 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--text-colour--faded);

        .nav-link {
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1 1 auto;
            transition: border-color 0.3s, color 0.3s;
            border-width: 0 0 3px 0;
            border-color: rgba(0, 0, 0, 0);
            height: 56px;

            &.active {
                background: none;
                color: var(--primary);
                border-color: var(--primary);

                &.danger {
                    color: var(--danger);
                    border-color: var(--danger);
                }

                &.warning {
                    color: var(--warning);
                    border-color: var(--warning);
                }

                &.info {
                    color: var(--info);
                    border-color: var(--info);
                }

                &.success {
                    color: var(--success);
                    border-color: var(--success);
                }
            }
        }
    }
}

.app .tab-content {
    background: none;
    border: none;
    position: relative;
    margin: 0;

    .tab-pane {
        display: none;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        transform: scale(0.01);
        top: 0;
        left: 0;
        transition: opacity 0.2s, transform 0s ease-in-out 0.2s;

        &.active {
            display: block;
            opacity: 1;
            pointer-events: all;
            position: static;
            transform: scale(1);
            transition: opacity 0.2s ease-in-out 0.2s, scale 0s ease-in-out 0.1s;
        }
    }
}

.card-table {
    .tab-pane {
        padding: 0;
    }
}

// Map
// =============================================================================
.world-map {
    position: relative;

    .world-map-legend {
        padding: 0;

        @media only screen and (min-width: 1200px) {
            position: absolute;
            top: 1.25rem;
            left: 1.25rem;
            width: 200px;
            box-shadow: var(--shadow-e-3);
            border-radius: 11px;
            background: var(--colour-e-3);
            padding: 1rem;
        }

        h5 {
            font-size: 1.05rem;
            margin: 1rem 0;
        }

        .scale {
            width: 100%;
            padding: 0 0.5rem 0.25rem 0.5rem;
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            align-items: center;

            .scale-labels {
                flex: 1 1 auto;
                width: 100%;
                max-width: 300px;
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
                color: var(--text-colour--faded);
            }

            .scale-track {
                flex: 1 1 auto;
                max-width: 300px;
                width: 100%;
                height: 6px;
                border-radius: 999px;
                background: linear-gradient(
                    90deg,
                    var(--colour-map--default) 0%,
                    var(--colour-map--6) 100%
                );
            }
        }
    }

    .map-container {
        display: flex;
        align-items: center;
        justify-content: center;

        .map-scale {
            width: 100%;
            max-width: 500px;
        }
    }
}

// Footer
// =============================================================================
html:not([dir='rtl']) .sidebar-lg-show.sidebar-fixed .app-footer {
    background: var(--colour-e-1);
    margin-left: 100px;
    border-color: var(--colour-e-1);
}

// Charts
// =============================================================================
.legend-container {
    display: flex;
    flex-flow: row nowrap;

    .legend-axis {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column nowrap;
        padding: 0 1rem;
        flex: 1 1 auto;

        &:first-child {
            align-items: flex-start;

            ul {
                justify-content: flex-start;
            }
        }

        &:last-child {
            align-items: flex-end;

            ul {
                justify-content: flex-end;
            }
        }

        .axis-label {
            font-size: 0.9rem;
            margin-bottom: 0.25rem;
            color: var(--text-colour--faded);
            display: none;
        }

        &:not(:last-child) {
            margin-right: 1rem;
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-flow: row wrap;

            li {
                flex-grow: 0 0 auto;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                padding: 5px 10px;
                border-radius: 10px;
                transition: background-color 0.3s;
                user-select: none;

                &:hover {
                    background-color: var(--colour-background-hover);
                }

                &:not(:last-child) {
                    margin-right: 0.5rem;
                }

                span {
                    display: block;
                    margin-right: 0.5rem;
                    padding: 2px;
                    border-radius: 8px;
                    color: #ffffff;
                }

                p {
                    margin: 0;
                    font-size: 0.8rem;
                    color: var(--text-colour--faded);
                }
            }
        }
    }
}

// Pie charts
// =============================================================================
.pie-chart-wrapper {
    max-width: 450px;
    padding: 0.75rem 0;
}

// Specific Pages
// =============================================================================
// =============================================================================
// =============================================================================

// Overview - Code Select
// =============================================================================
#code-select {
    .card-body {
        display: flex;
        align-items: center;
        justify-content: center;

        .current-codes-box {
            width: 100%;
            border-radius: 8px;
            transition: background-color 0.3s;
            overflow: hidden;
            padding: 0;
            box-shadow: none !important;
            height: auto;

            &:hover,
            &:active {
                background-color: var(--primary-translucent) !important;

                .current-code {
                    background-color: #eee !important;
                    color: var(--primary) !important;
                }
            }

            .scroll-container {
                padding: 24px;
                max-height: 250px;
                overflow-x: hidden;
                overflow-y: auto;
                display: flex;
                flex-flow: row wrap;
                align-items: center;
                justify-content: center;
                width: 100%;
                min-height: 80px;

                span {
                    font-size: 1.2rem;
                }

                .icon {
                    font-size: 28px;
                }

                .current-code {
                    text-transform: none;
                    margin: 8px 10px;
                    line-height: 1;
                    font-size: 1rem !important;
                    background: var(--primary);
                    color: var(--text-colour--active);
                    padding: 9px 15px;
                    border-radius: 9999px;
                    transition: background-color 0.3s, color 0.3s;
                }
            }
        }
    }

    .select-codes-box {
        button {
            flex-flow: column nowrap;
            padding: 12px 24px;
            height: auto;

            span {
                font-size: 16px;
            }

            .icon {
                margin-bottom: 5px;
                font-size: 21px;
            }
        }
    }
}

.my-widg {
    .card-header {
        font-size: 1.1rem;
    }

    .card-body {
        font-size: 1rem;
        padding-top: 0;
        font-size: 0.95rem;

        .value > span:first-child {
            margin-right: 7px;
        }
    }
}

// Referrals
// =============================================================================
.react-bs-table-container tr:not(.customColour) .clickable-cell {
    &,
    &.active {
        background-color: var(--primary-translucent);
        color: var(--text-regular);

        &:hover {
            background-color: var(--primary);
            color: var(--text-colour--active);
        }
    }
}

#referral-title {
    width:100%;
    background: var(--primary-light);
    color: #ffffff;
    border-radius: 20px 20px 0 0;
    display: flex;
    justify-content: center;
}

// Tooltip
// =============================================================================
.fa.fa-info-circle{
    font-size: 18px;
}