.react-table-container {
    .search {
        padding: 1.25rem 1.25rem;
        .search-input {
            height: 38px;
            width: 100%;
            display: inline-flex;
            border: none;
        }
        .search-icon {
            padding-left: 1.15rem;
            margin-right: 1.25rem;
        }
    }
}
