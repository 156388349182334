.my-widg {
    .title {
        display: flex;
        align-items: center;

        span {
            line-height: 1;
            padding: 2px 0 0 0;
        }
    }
}
