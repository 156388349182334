/* ========== Profile Header =========*/
.account .profile-header {
    position: relative;
    height: 40vh;
    margin-top: 1.5rem;
    overflow: hidden;

    .background-photo {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-size: cover;
        background-position: center;
    }

    .background-photo-hover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1001;

        &:hover .background-photo-hover-content {
            opacity: 1;
        }
    }

    .background-photo-spinner {
        position: absolute;
        z-index: 2000;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.7);
    }

    .background-photo-hover-content {
        position: absolute;
        width: auto;
        bottom: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: opacity 0.3s, background-color 0.3s;
        opacity: 0;
        z-index: 1000;
        padding: 16px;
        border-radius: 4px 0 0 0;

        &:hover {
            background-color: rgba(0, 0, 0, 0.75);
        }

        i,
        span {
            color: #fff;
            margin: 0 0 0 0;
        }

        span {
            font-size: 12px;
            text-align: center;
        }

        i {
            font-size: 30px;
        }
    }

    .radial-gradient {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: radial-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
    }

    .profile-photo-window {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        .profile-photo-wrapper {
            position: relative;
            width: 15vh;
            height: 15vh;
            border-radius: 50%;
            box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.75);
            transform: translate(0, -10px);
            transition: opacity 0.8s, transform 0.8s;
            opacity: 0;
            overflow: hidden;
            z-index: 1001;

            &.show {
                transform: translate(0);
                opacity: 1;
            }

            .profile-photo {
                background-size: cover;
                background-position: center;
                width: 100%;
                height: 100%;
            }

            .profile-photo-hover {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.5);
                display: flex;
                flex-flow: column nowrap;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                transition: opacity 0.3s;
                opacity: 0;

                &:hover {
                    opacity: 1;
                }

                i,
                .change-image-text {
                    color: #fff;
                    margin: 0 0 0 0;
                }

                .change-image-text {
                    max-width: 80%;
                    text-align: center;
                }

                .profile-photo-wrapper {
                    position: relative;
                    width: 15vh;
                    height: 15vh;
                    border-radius: 50%;
                    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.75);
                    transform: translate(0, -10px);
                    opacity: 0;
                    transition: opacity 0.8s, transform 0.8s;
                    overflow: hidden;

                    &.show {
                        transform: translate(0);
                        opacity: 1;
                    }

                    .profile-photo {
                        background-size: cover;
                        background-position: center;
                        width: 100%;
                        height: 100%;
                    }

                    .profile-photo-hover {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: rgba(0, 0, 0, 0.5);
                        display: flex;
                        flex-flow: column nowrap;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        transition: opacity 0.3s;
                        opacity: 0;
                        display: none; // Delete this to engage magic

                        &:hover {
                            opacity: 1;
                        }

                        i,
                        span {
                            color: #fff;
                            margin: 0 0 0 0;
                        }

                        span {
                            font-size: 12px;
                            width: 80%;
                            text-align: center;
                        }

                        .profile-photo-spinner {
                            position: absolute;
                            z-index: 2000;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background-color: rgba(0, 0, 0, 0.7);
                        }
                    }
                }
            }
        }
    }
}

/* ========== Contact Card =========*/
.contact-card .card-body .row {
    a {
        display: block;

        &:hover {
            text-decoration: none;
        }
    }
}
