// General styles
.context {
    .card-body {
        position: relative;

        #context-main-body {
            position: relative;

            @media only screen and (min-width: 1800px) {
                flex-flow: row nowrap;
            }
        }

        .date-select-container {
            @media only screen and (min-width: 1200px) and (max-width: 1799px) {
                width: 400px;
            }
        }

        .callout-container {
            @media only screen and (min-width: 576px) and (max-width: 1199px) {
                position: absolute;
                top: 0;
                right: 0;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
            }

            .callout {
                @media only screen and (min-width: 576px) and (max-width: 1199px) {
                    transform: translate(0, -0.75rem);
                }
            }
        }
    }
}

// Fix bug where opening the nav at sm breaks stuff
.sidebar-show .context .card-body {
    @media only screen and (min-width: 576px) and (max-width: 767px) {
        .tag-select-container {
            max-width: 100%;
            flex: 1 1 auto;
        }

        .callout-spacer {
            width: 100%;
            height: 56px;
            max-width: 100%;
            flex: 1 1 auto;
        }

        .callout-container {
            height: auto;
            top: 69px;
            left: initial;
            max-width: 100%;
        }

        .date-select-container {
            flex: 1 1 auto;
            max-width: 100%;
        }
    }
}
