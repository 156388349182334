#no-permissions {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column nowrap;
    height: calc(100vh - 53px);
    width: calc(100vw - 99px);
    position: fixed;
    left: 99px;

    .card {
        margin-bottom: 8vh;

        .card-body {
            padding: 6vh;

            span {
                width: 400px;
                display: block;
                text-align: center;
            }

            a {
                margin-top: 2rem;
                width: auto;
            }
        }
    }
}
