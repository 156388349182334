.download-navigation {
    margin: 0px;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    li {
        list-style: none;
    }
    .download {
        border: none;
        background: none;
        color: var(--primary);
        border-radius: 99999px;
        height: 36px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: none;
        cursor: pointer;
        padding: 0 1rem;
    }
    .download:hover {
        background-color: var(--primary-translucent-light);
        color: var(--primary);
    }
}
