.back-button {
    &.floating {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
    }

    span {
        font-size: 24px;
    }
}
