.react-table-pagination {
    .pagination-buttons {
        position: relative;

        .page-ellipsis {
            position: absolute;
            width: 30px;
            height: 100%;
            pointer-events: none;
            display: flex;
            align-items: flex-end;
            justify-content: center;

            &.ellipsis-append {
                right: 90px;
            }
            &.ellipsis-prepend {
                left: 90px;
            }
        }
        .btn {
            border-radius: 2px;
            min-width: 30px;
            height: 30px;
            padding: 5px 10px;
            outline: none !important;
            box-shadow: none !important;
            border-color: rgba(0, 0, 0, 0.87);
            color: rgba(0, 0, 0, 0.87);
            background-color: #fff;
            font-weight: 100;
        }
        .btn.active {
            color: rgba(42, 120, 189, 1) !important;
            border-color: rgba(42, 120, 189, 1) !important;
            background-color: #fff !important;
        }
    }
    // Pagination page size select dropdown css
    .pagination-select-menu {
        padding: 0;
        margin: 0;
        list-style: none;
        float: left;
        line-height: 10px;
        width: 100%;
        li:hover > ul {
            display: block;
        }
        ul {
            padding-left: 0;
        }
        li:hover,
        li {
            a:hover {
                transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
                    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            }
        }
        a {
            display: block;
            text-decoration: none;
            color: #000;
        }
        a:hover,
        a:active {
            color: #fff;
        }
        > ul > li {
            border-radius: 999px;
            border: 1px solid var(--text-colour--disabled);
            box-shadow: 0px 0px 5px 5px #00000012;
            list-style: none;
        }
        > ul > li:hover,
        > ul > li:active {
            background-color: var(--primary-hover) !important;
            border-color: var(--primary-hover) !important;
            color: #fff;
        }
        > ul > a:hover {
            color: #fff;
        }
        ul li ul li {
            border: none;
            color: #000;
            padding-top: 3px;
            padding-bottom: 3px;
            padding-left: 3px;
            padding-right: 3px;
            background: #fff;
        }
        ul li {
            float: left;
            background: #fff;
        }
        ul li:hover > a {
            color: #fff;
        }
        ul li:hover > ul li a {
            color: #000;
        }
        ul li a {
            padding: 0.6rem 1.25rem;
        }
        li {
            position: relative;
            float: left;
        }
        ul li ul {
            box-shadow: 0px 0px 5px 5px #00000012;
            padding: 0.25rem 0;
            border-radius: 4px;
            background-color: #fff;
            margin-bottom: 2px;
        }
        ul li ul li {
            width: 160px;
        }
        ul li ul li:hover {
            background-color: var(--colour-background-hover);
            color: var(--text-colour--solid);
        }

        ul li ul li a:hover {
            color: var(--text-colour--solid);
        }
        ul li ul,
        &:hover ul li ul {
            display: none;
            list-style-type: none;
        }
        &:hover ul,
        &:hover ul li:hover ul,
        &:hover ul li:hover ul li:hover ul {
            display: block;
        }
        &:hover ul li:hover ul {
            position: absolute;
            margin-top: 1px;
            bottom: 100%;
        }
        span {
            margin-left: 5px;
        }
    }
}
