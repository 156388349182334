.world-map {
  &__legend {
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;

    li {
      display: flex;
      align-items: center;
    }

    li:not(:last-child) {
      margin-right: 10px;
    }
  }

  &__circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 10px;
  }
}
