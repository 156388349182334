#no-network-publisher {
    .card-body {
        min-height: 80px;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        text-align: center;

        .icon {
            margin-bottom: 10px;
            font-size: 24px;
        }

        span {
            font-size: 20px;
            color: var(--grey);
        }
    }
}
