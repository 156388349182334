.button-ripple {
    --top: 0;
    --left: 0;
    position: relative;
    overflow: hidden;
    border: none;
    background: none;
    border-radius: 999999px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    padding: 8px;
    transition: background-color 0.3s;

    &:hover,
    &:focus,
    &:active {
        background-color: rgba(0, 0, 0, 0.08);
    }

    &.ink-ripple-play {
        &:before {
            content: '';
            position: absolute;
            top: var(--top);
            left: var(--left);
            animation: ink 0.7s ease-in;
            background: var(--ripple-colour);
            width: 5%;
            padding-bottom: 5%;
            border-radius: 50%;
            opacity: 0;
            transform: scale(0);
        }

        &.ripple-primary:before {
            background-color: var(--primary-translucent-dark);
        }
    }

    * {
        pointer-events: none;
    }
}

@keyframes ink {
    from {
        opacity: 0.2;
    }
    to {
        transform: scale(333);
    }
}
