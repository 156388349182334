.login-modal.modal-backdrop {
    background-color: var(--colour-e-4);
    background-image: var(--login-background);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    &.show {
        opacity: 1;
    }
}

.modal {
    .login-modal.modal-dialog {
        margin-bottom: 0px !important;

        .modal-content {
            width: 420px;
            max-width: 100%;
            min-height: 200px;
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            justify-content: center;

            .modal-header {
                border: none;
                border-radius: 20px 12px 0 0;
                display: flex;
                flex-flow: column nowrap;
                padding: 1.75rem;
                width: 100%;

                svg {
                    opacity: 0.8;
                    width: 80px;
                    margin: 0 0 0.75rem 0;

                    polygon {
                        fill: var(--text-colour--disabled);
                    }
                }

                h1 {
                    font-size: 20px;
                    color: var(--text-colour--faded);
                }
            }

            .modal-body {
                padding: 0.5rem 1.75rem 1.75rem 1.75rem;
                width: 100%;

                #login-password-container {
                    margin-bottom: 0;
                }

                .forgot-password-button {
                    display: flex;
                    justify-content: flex-end;
                    margin-top: 7px;
                }

                .mfa-expand {
                    &:before {
                        right: 3px;
                    }

                    &.rotate-180 {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }
}
