#fab {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    color: #fff;
    opacity: 0;
    pointer-events: none;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    padding: 0;
    transition: opacity 0.3s;

    &.show {
        opacity: 1;
        pointer-events: initial;
    }

    .fab-shadow {
        -webkit-box-shadow: 0px 2px 5px 3px rgba(0, 0, 0, 0.25);
        -moz-box-shadow: 0px 2px 5px 3px rgba(0, 0, 0, 0.25);
        box-shadow: 0px 2px 5px 3px rgba(0, 0, 0, 0.25);
        border-radius: 50%;
        transition: box-shadow 0.3s;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        pointer-events: none;
        transform: scale(0.9);
        transition: transform 0.3s;
    }

    .fab-background {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
    }

    .fab-icon {
        font-size: 21px;
    }
}

#fab.fab-text {
    width: auto;
    height: 50px;

    border-radius: 9999px;

    .fab-background {
        position: relative;
        padding: 0 1.25rem;
        width: auto;
        height: 50px;
        border-radius: 9999px;
        flex-flow: row nowrap;
    }

    .fab-shadow {
        height: 50px;
        border-radius: 9999px;
    }

    &:hover .fab-shadow {
        transform: scale(1);
    }

    .fab-icon {
        font-size: 21px;
        margin-right: 0.5rem;
    }

    .fab-text {
        font-size: 0.875rem;
    }
}
