.list {
    list-style: none;
    margin: 0;
    padding: 0;
    width: auto;
    height: auto;
    overflow-y: auto;
    overflow-x: hidden;
    list-style: none;
    margin: 0;
    padding: 8px 0;

    .option {
        padding: 12px 16px;
        cursor: pointer;
        border-bottom: 1px solid #ccc;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &:hover {
            background-color: #ccc;
            color: #000;
        }

        &.cascade {
            .cascade-arrow {
                margin: 0 0 0 3.5rem;
            }
        }

        * {
            user-select: none;
        }

        span {
            flex: 1 1 auto;
        }
    }
}

.menu .list {
    max-height: 30vh;
}

.menu.reverse {
    .list {
        .option {
            &.cascade {
                .option-text {
                    order: 2;
                }
                .cascade-arrow {
                    order: 1;
                    margin: 0 3.5rem 0 0;
                    transform: rotate(180deg);
                }
            }
        }
    }
}
