.modal {
    min-height: 800px;
    display: flex !important;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;

    .modal-dialog {
        .modal-content {
            background-color: var(--colour-e-24);
            box-shadow: var(--shadow-e-24);
            border-radius: 20px;
            border: none;
            margin: 0 0 10vh 0;
            min-width: 400px;
            max-width: calc(100vw - 2.5rem);
            max-height: calc(100vh - 2.5rem);

            .modal-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-color: var(--colour-divider);
                padding: 22px 24px 15px 24px;

                .modal-header-content {
                    width: 100%;
                    display: flex;
                    flex-flow: column nowrap;
                    align-items: center;
                    justify-content: space-between;
                    position: relative;

                    .modal-title {
                        color: var(--text-colour--regular);
                        -webkit-font-smoothing: antialiased;
                        font-size: 1.5rem;
                        line-height: 1;
                        font-weight: 500;
                        letter-spacing: 0.0125em;
                    }

                    button span {
                        color: var(--text-colour--faded);
                    }
                }

                h1,
                h2,
                h3,
                h4,
                h5 {
                    margin-bottom: 0;
                }

                // Hack to make h5 generated by modal header behave like h3
                h5,
                h5.h5 {
                    font-weight: 300;
                    font-size: 1.53125rem;
                }

                .close {
                    float: none;
                    padding: 1.5rem 1.5rem;
                    margin: -1.5rem -1rem -1.5rem auto;
                    display: none;
                }
            }

            .modal-body {
                max-height: calc(100vh - 155px);
                overflow: auto;
                display: flex;
                flex-flow: column nowrap;
                align-items: center;
                justify-content: center;
                padding: 1.75rem;

                > * {
                    flex: 1 1 auto;
                    width: 100%;
                }

                @media only screen and (min-width: 576px) {
                    max-height: calc(100vh - 200px);
                }
            }
        }
    }

    .modal-code-select {
        tr {
            cursor: pointer;
        }
    }
}
