.revenue-lost.percentage-widget {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    height: calc(100% - 1.5rem);
    min-height: 170px;
    margin-bottom: 1.5rem;

    .card-body {
        flex: 1 1 auto;
        font-size: 30px;
        padding: 0 1.75rem rem;
        align-items: center;
        justify-content: center;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 2rem;

        &:before,
        &:after {
            content: '';
            height: 100%;
            width: 5px;
            background: var(--danger);
            margin: 1rem;
        }

        &:before {
            border-radius: 5px 0 0 5px;
        }

        &:after {
            border-radius: 0 5px 5px 0;
        }
    }
}
